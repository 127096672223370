import { useState } from 'react';

import { Alert, Box, Button, Snackbar, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { downloadActivityMetricsReport } from 'api/apiThunks';
import { downloadFile } from 'utils/files';
import { asDate } from 'utils/convert';

interface OrganizationRoiSummaryTableProps {
  data: RoiSummaryStats | null;
  orgName?: string;
}

enum FileType {
  Excel = 'excel',
  Ppt = 'ppt',
}

function changePropertyNameToWord(camelStr: string): string {
  return camelStr
    .replace(/([a-z0-9])([A-Z])/g, '$1 $2')
    .replace(/^([a-z])/g, (match) => match.toUpperCase())
    .replace(/([A-Z])/g, (match) => match.toUpperCase());
}

const Data = ({
  label,
  value,
  valueSize = 'h5',
  bgColor = '#f9f9f9',
}: {
  label: string;
  value: string | number;
  valueSize?: 'h4' | 'h5';
  bgColor?: string;
}) => {
  return (
    <div style={{ background: bgColor, padding: 16 }}>
      <Typography variant="body2" color="textSecondary">
        {label}
      </Typography>
      <Typography variant={valueSize} fontWeight={'bold'}>
        {value}
      </Typography>
    </div>
  );
};

const Label = ({ label }: { label: string }) => {
  return (
    <Typography variant="body1" style={{ fontSize: '16px', fontWeight: 500, marginBottom: 8 }}>
      {label}
    </Typography>
  );
};

export const OrganizationRoiSummaryTable = ({
  data,
  orgName = '',
}: OrganizationRoiSummaryTableProps) => {
  const [loadingButtonName, setLoadingButtonName] = useState('');
  const [error, setError] = useState('');

  let dateRange = '';
  if (data?.startDate && data?.endDate) {
    const startDate = asDate(data.startDate).format('DD-MM-YYYY');
    const endDate = asDate(data.endDate).format('DD-MM-YYYY');
    dateRange = `${startDate}-${endDate}`;
  }

  const getFileNameAndType = (fileType: FileType) => {
    switch (fileType) {
      case FileType.Excel:
        return {
          fileName: `${orgName}${dateRange}Activity Metrics Report.xlsx`,
          fileType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        };
      case FileType.Ppt:
        return {
          fileName: `${orgName}${dateRange}QRDeck.pptx`,
          fileType: 'application/vnd.ms-powerpoint',
        };
      default:
        return {};
    }
  };

  const download = async (type: FileType) => {
    if (!data || loadingButtonName) return;

    try {
      setLoadingButtonName(type);
      const response: string = await downloadActivityMetricsReport({ ...data, orgName }, type);
      const { fileName, fileType } = getFileNameAndType(type);
      if (!fileName || !fileType) return;
      downloadFile(fileName, response, fileType);
    } catch (error) {
      const message = error instanceof Error ? error.message : `${error}`;
      setError(`Failed to download the ${type} file. ${message}`);
    } finally {
      setLoadingButtonName('');
    }
  };

  if (!data) return <>No data found!</>;
  const {
    uniqueTotal,
    uniqueDiverse,
    diversityStats,
    profileTypes,
    enhancedProfiles,
    activityPercentages,
    totalProfiles,
  } = data;
  return (
    <div style={{ overflow: 'hidden' }}>
      <Box display="flex" flexDirection="row" justifyContent="start" alignItems="center">
        <Box flexGrow={1} width="50%" marginRight={1}>
          <Data
            label="Total profiles that users took action on"
            value={totalProfiles}
            valueSize="h4"
            bgColor="#ECECEB"
          />
        </Box>
        <Box flexGrow={1} width="50%" marginRight={1}>
          <Data
            label="Unique profiles that users took action on"
            value={uniqueTotal}
            valueSize="h4"
            bgColor="#ECECEB"
          />
        </Box>
        <Box flexGrow={1} width="50%">
          <Data label="Unique Diverse" value={uniqueDiverse} valueSize="h4" bgColor="#ECECEB" />
        </Box>
      </Box>

      <br />
      <Label label="Diversity" />
      <Box display="flex" flexDirection="row" justifyContent="start" alignItems="center">
        {Object.entries(diversityStats).map(([key, value]) => (
          <Box flexGrow={1} marginRight={1} width={'20%'} key={key}>
            <Data label={changePropertyNameToWord(key)} value={value} />
          </Box>
        ))}
      </Box>

      <br />
      <Label label="Talent pools" />
      <Box display="flex" flexDirection="row" justifyContent="start" alignItems="center">
        {Object.entries(profileTypes).map(([key, value]) => (
          <Box flexGrow={1} marginRight={1} width={'14%'} key={key}>
            <Data label={changePropertyNameToWord(key)} value={value} />
          </Box>
        ))}
        <Box flexGrow={1} marginRight={1} width={'14%'}>
          <Data label={'Github'} value={enhancedProfiles.github} />
        </Box>
        <Box flexGrow={1} marginRight={1} width={'14%'}>
          <Data label={'Expert'} value={enhancedProfiles.expert} />
        </Box>
      </Box>

      <br />
      <Label label="Profiles with" />
      <Box display="flex" flexDirection="row" justifyContent="start" alignItems="center">
        <Box flexGrow={1} marginRight={1} width={'14%'}>
          <Data label={'Security clearance'} value={enhancedProfiles.clearance} />
        </Box>
        <Box flexGrow={1} marginRight={1} width={'14%'}>
          <Data label={'Enriched ATS'} value={enhancedProfiles.atsEnhanced} />
        </Box>
      </Box>

      <br />
      <Label label="Activity type" />
      <Box display="flex" flexDirection="row" justifyContent="start" alignItems="center">
        {Object.entries(activityPercentages).map(([key, value]) => (
          <Box flexGrow={1} width={'20%'} marginRight={1} key={key}>
            <Data label={changePropertyNameToWord(key)} value={value} />
          </Box>
        ))}
      </Box>

      <Typography variant="body1" fontSize={'16px'} fontWeight={500} marginTop={3}>
        Top 25 and 50 lists included in report
      </Typography>
      <Typography variant="body1" fontSize={24} fontWeight={500}>
        Companies, Titles, Diverse Titles, Cleared Titles
      </Typography>

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="end"
        alignItems="center"
        marginTop={3}
      >
        <Button
          variant="contained"
          sx={{ marginRight: 1 }}
          onClick={() => download(FileType.Excel)}
          startIcon={
            loadingButtonName === FileType.Excel ? (
              <CircularProgress size={20} color="inherit" />
            ) : null
          }
        >
          DOWNLOAD XLS
        </Button>
        <Button
          variant="contained"
          sx={{ marginRight: 1 }}
          onClick={() => download(FileType.Ppt)}
          startIcon={
            loadingButtonName === FileType.Ppt ? (
              <CircularProgress size={20} color="inherit" />
            ) : null
          }
        >
          DOWNLOAD PPT
        </Button>
      </Box>

      <Snackbar
        open={!!error}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={() => setError('')}
      >
        <Alert onClose={() => setError('')} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
};
