import { createAsyncThunk } from '@reduxjs/toolkit';
import { NoLicenseSku } from 'constant';
import { asDateISOString, isEmail, isGuid } from 'utils/convert';
import { ActivityTypes } from 'utils/enums';
import { fetchWrapper } from 'utils/fetchWrapper';
import { getIntegrationPath } from 'utils/convert';
import { getCamelCaseEntityType } from 'utils/integrations';

type APIBase = 'old' | 'new' | 'recent';
type RequestBody = Record<string, unknown> | FormData | Blob | string | undefined;

/*
 * This file contains all the thunks that control communication with the frontend API
 * They are organized (roughly) by Area and/or Controller
 */

// #region Admin

export const findDeletedLIProfileByEmail = (email: string) => {
  return (
    needTruthy(email) ||
    post<IDeletedLIProfile[]>('new', `admin/findDeletedLIProfileByEmail/${email}`)
  );
};

export const findDeletedLIProfileById = (id: string) => {
  return needTruthy(id) || post<IDeletedLIProfile>('new', `admin/findDeletedLIProfile/${id}`);
};

export const deleteLIProfile = (searchField: string, value: string) => {
  const queryParam = searchField === 'Email' ? 'email' : 'handle';
  return (
    needTruthy(value) ||
    delWithResult<IDeletedLIProfile>('new', `admin/deleteLIProfile?${queryParam}=${value}`)
  );
};

export const deleteAllLIProfiles = () => {
  return delWithResult<Record<string, number>>('new', 'admin/deleteLIProfiles');
};

// #endregion

// #region ATS

export const getAtsCandidateStatus = createAsyncThunk(
  'getAtsCandidateStatus',
  ({ atsId, candidateId }: AtsParams) => {
    return (
      needTruthy(atsId) ||
      needTruthy(candidateId) ||
      get<IATSCandidateStatus>(
        'new',
        `atsconfig/atsCandidateStatus?id=${candidateId}&atsId=${atsId}`
      )
    );
  }
);

export const getAtsConnections = createAsyncThunk('getAtsConnections', (orgId?: string) => {
  if (orgId) {
    return (
      needGuid(orgId) || get<IATSConnection[]>('new', `atsConfig/atsConnections?orgId=${orgId}`)
    );
  }

  return get<IATSConnection[]>('new', 'atsConfig/atsConnections');
});

export const updateAtsConnection = createAsyncThunk(
  'updateAtsConnection',
  ({ resource }: ResourceParams<AtsConnectionInput>) => {
    return (
      needGuid(resource.orgId) || put<IATSConnection>('new', `atsConfig/atsConnection`, resource)
    );
  }
);

export const getATSExportHistory = createAsyncThunk(
  'getATSExportHistory',
  ({ value: email }: ValueParams) => {
    return (
      needEmail(email) || get<IATSExportLogFromDB[]>('new', `atsConfig/logs/email?emailId=${email}`)
    );
  }
);

export const getOrgATSExportHistory = createAsyncThunk(
  'getOrgATSExportHistory',
  ({ orgId, value: atsType }: ValueParams) => {
    return (
      needGuid(orgId) ||
      needTruthy(atsType) ||
      get<IATSExportLogFromDB[]>('new', `atsConfig/logs/${orgId}?atsType=${atsType}`)
    );
  }
);

export const getATSReports = createAsyncThunk('getATSReports', () => {
  return get<IATSReport[]>('new', 'atsConfig/atsReports');
});

export const getATSSupported = createAsyncThunk('getATSSupported', () => {
  return get<IATSMeta[]>('new', 'atsMeta');
});

const uploadAtsMetaFile = (atsMetaId: string, file: UploadedFile) => {
  return (
    needTruthy(atsMetaId) ||
    put<string>('new', `atsMeta/files/${atsMetaId}/${file.fileId}`, file.fileData)
  );
};

export const createOrUpdateAtsMeta = createAsyncThunk(
  'createOrUpdateAtsMeta',
  async ({ resource, files }: AtsMetaParams, { rejectWithValue }) => {
    try {
      // 1. Save the Ats Meta
      const response = await put<IATSMeta>('new', `atsMeta`, resource);
      if (files) {
        // 2. Upload logo and tutorial files if present
        for (const file of files) {
          try {
            const fileUrl = await uploadAtsMetaFile(response.id, file);
            switch (file.fileId) {
              case 'logo_file':
                response.logoUrl = fileUrl;
                break;

              case 'tutorial_file':
                response.tutorialUrl = fileUrl;
                break;

              default:
                break;
            }
          } catch (e) {
            return rejectWithValue(response);
          }
        }
      }
      return response;
    } catch (e) {
      return rejectWithValue(undefined);
    }
  }
);

export const deleteAtsMeta = createAsyncThunk(
  'deleteAtsMeta',
  ({ value: atsMetaId }: ValueParams) => {
    return needTruthy(atsMetaId) || del('new', `atsMeta/${atsMetaId}`);
  }
);

//#endregion

// #region Organization

export const getOrganization = createAsyncThunk('getOrganization', (lookup: Lookup) => {
  if (!lookup.id && !lookup.domain) return Promise.reject('No Identifier Provided');
  return get<IOrganization>(
    'new',
    lookup.id
      ? `organizations?id=${lookup.id}`
      : `organizations/find?domain=${lookup.domain}&full=true`
  );
});

const uploadOrganizationIcon = (orgId: string, file: UploadedFile) => {
  return needGuid(orgId) || put<string>('new', `organizations/${orgId}/icon`, file.fileData);
};

const upsertOrganization = async (resource: Partial<IOrganization>, iconFile?: UploadedFile) => {
  const upsert = resource.id ? put : post;
  const response = await upsert<IOrganization>('new', 'organizations', resource);
  if (iconFile) {
    response.icon = await uploadOrganizationIcon(response.id, iconFile);
  }
  return response;
};

export const createOrganization = createAsyncThunk(
  'createOrganization',
  async ({ resource, iconFile }: OrgParams, { rejectWithValue }) => {
    try {
      return upsertOrganization(resource, iconFile);
    } catch (e) {
      return rejectWithValue(undefined);
    }
  }
);

export const updateOrganization = createAsyncThunk(
  'updateOrganization',
  async ({ resource, iconFile }: OrgParams, { rejectWithValue }) => {
    // check and convert the string values to number
    const fields = ['inboundTalent', 'internalTalent', 'healthcare', 'nursing'];
    for (const field of fields) {
      const fieldName = field as keyof IOrganization;
      if (typeof resource[fieldName] === 'string') {
        resource[fieldName] = parseInt(resource[fieldName], 10);
      }
    }
    try {
      return upsertOrganization(resource, iconFile);
    } catch (e) {
      return rejectWithValue(undefined);
    }
  }
);

export const getOrganizationTeams = createAsyncThunk(
  'getOrganizationTeams',
  (orgId: string) => needGuid(orgId) || get<IOrgTeam[]>('new', `organizations/${orgId}/teams`)
);

export const addTeam = createAsyncThunk(
  'addTeam',
  ({ orgId, value: teamInput }: KeyValueParams) => {
    return (
      needGuid(orgId) ||
      needTruthy(teamInput) ||
      put<IOrgTeam>('new', `organizations/${orgId}/teams`, teamInput as Record<string, unknown>)
    );
  }
);

export const deleteTeam = createAsyncThunk(
  'deleteTeam',
  ({ orgId, value: teamId }: ValueParams) => {
    return (
      needGuid(orgId) || needGuid(teamId) || del('new', `organizations/${orgId}/teams/${teamId}`)
    );
  }
);

export const assignTeam = createAsyncThunk(
  'assignTeam',
  ({ orgId, userId, value: teamId }: ValueParams) => {
    return (
      needGuid(orgId) ||
      needGuid(userId) ||
      needGuid(teamId) ||
      put<ITeamRecruiter>('new', `organizations/${orgId}/teams/${teamId}/recruiters`, {
        recruiterUserId: userId,
      })
    );
  }
);

export const removeTeam = createAsyncThunk(
  'removeTeam',
  ({ orgId, userId, value: teamId }: ValueParams) => {
    return (
      needGuid(orgId) ||
      needGuid(userId) ||
      needGuid(teamId) ||
      del('new', `organizations/${orgId}/teams/${teamId}/recruiter/${userId}`)
    );
  }
);

export const getOrgSearchHistory = createAsyncThunk(
  'getOrgSearchHistory',
  ({ startDate, endDate, orgId }: DateRangeParams) => {
    const { startIso, endIso, reject } = needDateRange(startDate, endDate);
    return (
      reject ||
      needGuid(orgId) ||
      post<ISearchHistory[]>(
        'new',
        `organizations/getOrgUsersSearchHistory?startDate=${startIso}&endDate=${endIso}&organizationId=${orgId}`
      )
    );
  }
);

export const getOrganizationsByDomainContains = createAsyncThunk(
  'getOrganizationsByDomainContains',
  ({ value: orgDomain }: ValueParams) =>
    get<IOrganization[]>('new', `organizations/find/contains?domain=${orgDomain}`)
);

export const getOrgPowerFilters = createAsyncThunk(
  'getOrgPowerFilters',
  (orgId: string) => needGuid(orgId) || get<IRecipe[]>('new', `recipes?orgId=${orgId}`)
);

export const createOrUpdateOrgPowerFilter = createAsyncThunk(
  'createOrUpdateOrgPowerFilter',
  ({ orgId, resource }: ResourceParams<IRecipe>) => {
    const upsert = resource.id ? put : post;
    return needGuid(orgId) || upsert<IRecipe>('new', `recipes?orgId=${orgId}`, resource);
  }
);

export const deleteOrgPowerFilter = createAsyncThunk(
  'deleteOrgPowerFilter',
  ({ value: recipeId }: ValueParams) => needGuid(recipeId) || del('new', `recipes/${recipeId}`)
);

export const getOrgExportFormatFields = createAsyncThunk(
  'getOrgExportFormatFields',
  ({ value: orgDomain }: ValueParams) =>
    needTruthy(orgDomain) || get<string[]>('new', `exportformat/fields?domain=${orgDomain}`)
);

export const getOrgExportFormats = createAsyncThunk(
  'getOrgExportFormats',
  (orgId: string) => needGuid(orgId) || get<IExportFormat[]>('new', `exportformat?userId=${orgId}`)
);

export const updateOrgFeature = createAsyncThunk(
  'updateOrgFeature',
  ({ orgId, resource }: ResourceParams<IOrgFeature>) => {
    return (
      needGuid(orgId) ||
      needTruthy(resource.id) ||
      put<IOrganization>(
        'new',
        `organizations/${orgId}/feature?featureId=${resource.id}&access=${resource.fAccess}`
      )
    );
  }
);

export const deleteOrgFeature = createAsyncThunk(
  'deleteOrgFeature',
  ({ orgId, resource }: ResourceParams<IOrgFeature>) => {
    return (
      needGuid(orgId) ||
      needTruthy(resource.id) ||
      delWithResult<IOrganization>('new', `organizations/${orgId}/feature?featureId=${resource.id}`)
    );
  }
);

export const updateDiversitySettings = createAsyncThunk(
  'updateDiversitySettings',
  ({ orgId, key: indexName, value: indexSetting, remove }: KeyValueParams) => {
    return (
      needGuid(orgId) ||
      needTruthy(indexName) ||
      put<IOrganization>(
        'new',
        `organizations/${orgId}/diversitySettings?indexName=${indexName}&indexSetting=${indexSetting}&remove=${
          remove ? '1' : undefined
        }`
      )
    );
  }
);

export const updateSearchFiltersOrders = createAsyncThunk(
  'updateSearchFiltersOrders',
  ({ orgId, value: searchFiltersOrder }: KeyValueParams) => {
    return (
      needGuid(orgId) ||
      needTruthy(searchFiltersOrder) ||
      put<IOrganization>(
        'new',
        `organizations/${orgId}/searchFiltersOrder`,
        searchFiltersOrder as Record<string, unknown>
      )
    );
  }
);

export const copyCreditInfo = createAsyncThunk(
  'copyCreditInfo',
  (orgId: string) =>
    needGuid(orgId) || post<IOrganization>('new', `organizations/${orgId}/pooldataforusers`)
);

export const deleteOrganization = createAsyncThunk(
  'deleteOrganization',
  ({ orgId, value: password }: ValueParams) => {
    return (
      needGuid(orgId) ||
      needTruthy(password) ||
      delWithResult<unknown>('new', `organizations/${orgId}`, { password })
    );
  }
);

export const getOrgROIReports = createAsyncThunk('getOrgROIReports', async (orgId: string) => {
  return (
    needGuid(orgId) ||
    get<IROIReport[]>('old', `analytics-proxy/getReportsWorkflows?orgId=${orgId}`)
  );
});

export const createOrgROIReport = createAsyncThunk(
  'createOrgROIReport',
  async ({ orgId, resource }: ResourceParams<IROIReportInput>, { rejectWithValue }) => {
    try {
      await (needGuid(orgId) || needTruthy(resource));
      if (resource.hireDataFile) {
        const formData = new FormData();
        formData.append('file', resource.hireDataFile);
        // 1. Upload hire data file if present
        await put(
          'old',
          `analytics-proxy/uploadHireDataFile?hireDataFileName=${resource.hireDataFileName}`,
          formData
        );
      }
      // 2. Create ROI report
      delete resource.hireDataFile;
      const response = await post<Record<string, string>>(
        'old',
        `analytics-proxy/createReport?orgId=${orgId}`,
        resource
      );
      if (!response?.workflowId) {
        return rejectWithValue(undefined);
      }
      return response;
    } catch (e) {
      return rejectWithValue(undefined);
    }
  }
);

export const getWorkflowStatus = createAsyncThunk(
  'getWorkflowStatus',
  ({ orgId, value: workflowId }: ValueParams) => {
    return (
      needGuid(orgId) ||
      needTruthy(workflowId) ||
      get<IROIReport>('old', `analytics-proxy/getStatus?orgId=${orgId}&workflowId=${workflowId}`)
    );
  }
);

export const terminateWorkflow = createAsyncThunk(
  'terminateWorkflow',
  ({ orgId, resource }: ResourceParams<Record<string, unknown>>) => {
    return (
      needGuid(orgId) ||
      needTruthy(resource) ||
      post<Record<string, unknown>>(
        'old',
        `analytics-proxy/terminateWorkflow?orgId=${orgId}`,
        resource
      )
    );
  }
);

export const downloadROIReport = (reportId: string) => {
  return (
    needTruthy(reportId) ||
    get<Record<string, string>>('old', `analytics-proxy/downloadReport?reportId=${reportId}`)
  );
};

export const generateActivityMetrics = (file: UploadedFile) => {
  const formData = new FormData();
  formData.append('file', file.fileData);
  return post<RoiSummaryStats>('new', 'analytics-proxy/generateActivityMetrics', formData);
};

export const generateActivityMetricsFromUrl = (
  sasUrl: string,
  startTime: string,
  endTime: string
) => {
  return post<RoiSummaryStats>('new', 'analytics-proxy/generateActivityMetrics', {
    sasUrl,
    startTime,
    endTime,
  });
};

export const downloadActivityMetricsReport = (
  summaryStats: RoiSummaryStats,
  format: 'excel' | 'ppt'
) => {
  return post<string>('new', `analytics-proxy/downloadActivityMetricsReport?format=${format}`, {
    ...summaryStats,
  });
};

export const getAtsRediscoveryLogs = createAsyncThunk(
  'getAtsRediscoveryLogs',
  async ({ resource }: ResourceParams<Record<string, string>>) => {
    const { query, timeSpan } = resource;
    const response = await (needTruthy(query) ||
      needTruthy(timeSpan) ||
      post<IRediscoveryInsights>(
        'new',
        `analytics/query/REDISCOVERY_INSIGHTS?timespan=${timeSpan}`,
        { query }
      ));
    let results: IAtsRediscoveryLog[] = [];
    try {
      const [table] = response.tables || [];
      results = table.rows.map((row) => {
        const record: Record<string, string> = {};
        table.columns.forEach((col, index) => {
          record[col.name] = row[index];
        });
        return record as unknown as IAtsRediscoveryLog;
      });
    } catch (e) {
      console.error(e);
      results = [];
    }
    return results;
  }
);

export const getAllFieldData = createAsyncThunk('getAllFieldData', (orgId: string) => {
  return needGuid(orgId) || get<AllFieldData>('old', `organizations/${orgId}/fields/admin`);
});

export const updateInternalSavedField = createAsyncThunk(
  'updateField',
  ({ orgId, field }: SavedFieldParams) => {
    return put<InternalSavedField>('old', `organizations/${orgId}/teams/field/${field?.id}`, {
      ...field,
    });
  }
);

export const saveNewInternalFields = createAsyncThunk(
  'saveNewInternalFields',
  ({ orgId, fields }: SavedFieldParams) => {
    return post<InternalSavedField[]>(
      'old',
      `organizations/${orgId}/fields/bulk`,
      fields as undefined
    );
  }
);

export const deleteInternalField = createAsyncThunk(
  'deleteInternalField',
  ({ orgId, userId, value: fieldId }: ValueParams) => {
    return (
      needGuid(orgId) ||
      needTruthy(userId) ||
      needGuid(fieldId) ||
      del('old', `organizations/${orgId}/teams/field/${userId}/${fieldId}`)
    );
  }
);

export const getAdditionalOrganizations = createAsyncThunk(
  'getAdditionalOrganizations',
  ({ value: orgDomain }: ValueParams) =>
    get<IOrganization[]>('new', `organizations/find/contains?domain=${orgDomain}`)
);

export const getOrganizationsByIds = (orgIds: string[]) => {
  return needTruthy(orgIds) || post<IOrganization[]>('new', `organizations/find`, { orgIds });
};

// #endregion

// #region Export Format

export const getSystemExportFormats = createAsyncThunk('getOrgExportFormats', () =>
  get<IExportFormat[]>('new', 'exportformat')
);

export const getExportFormatFields = createAsyncThunk('getExportFormatFields', () =>
  get<string[]>('new', 'exportformat/fields')
);

export const createOrUpdateExportFormat = createAsyncThunk(
  'createOrUpdateExportFormat',
  ({ resource }: ResourceParams<IExportFormat>) =>
    put<IExportFormat>('new', 'exportformat', resource)
);

export const deleteExportFormat = createAsyncThunk(
  'deleteExportFormat',
  ({ orgId = '', value: exportFormatId }: ValueParams) =>
    needGuid(exportFormatId) || del('new', `exportformat/${exportFormatId}?userId=${orgId}`)
);

export const updateExportFormatField = createAsyncThunk(
  'updateExportFormatField',
  ({ orgId = '', exportFormatId, fieldName, fieldLabel }: ExportFormatFieldParams) =>
    needTruthy(exportFormatId) ||
    needTruthy(fieldName) ||
    needTruthy(fieldLabel) ||
    put<IExportFormat>(
      'new',
      `exportformat/${exportFormatId}/field?fieldName=${fieldName}&fieldLabel=${encodeURIComponent(
        fieldLabel
      )}&userId=${orgId}`
    )
);

export const deleteExportFormatField = createAsyncThunk(
  'deleteExportFormatField',
  ({ orgId = '', exportFormatId, fieldName }: Partial<ExportFormatFieldParams>) =>
    needTruthy(exportFormatId) ||
    needTruthy(fieldName) ||
    delWithResult<IExportFormat>(
      'new',
      `exportformat/${exportFormatId}/field?fieldName=${fieldName}&userId=${orgId}`
    )
);

// #endregion

// #region SMB

export const createInviteLinks = (userEmails: string[]) => {
  return post<SMBInviteDef[]>('new', 'smb/invite', { userEmails });
};

export const getSmbDomains = createAsyncThunk('getSmbDomains', () => {
  return get<ISmbDomain[]>('new', `smb/domain`);
});

export const getInactiveSmbUsers = createAsyncThunk('getInactiveSmbUsers', () => {
  return get<INewUser[]>('new', `smb/users?activated=false`);
});

export const addSmbDomain = createAsyncThunk(
  'addSmbDomain',
  ({ resource }: ResourceParams<ISmbDomain>) => {
    return (
      needTruthy(resource) ||
      post<ISmbDomain>('new', 'smb/domain', {
        domain: resource.userId,
        allowStatus: resource.allowStatus,
      })
    );
  }
);

export const deleteSmbDomain = createAsyncThunk(
  'deleteSmbDomain',
  ({ value: domain }: ValueParams) => {
    return needTruthy(domain) || del('new', `smb/domain?domain=${encodeURIComponent(domain)}`);
  }
);

export const getTransactionHistory = createAsyncThunk(
  'getTransactionHistory',
  ({ value: subscriptionId }: ValueParams) => {
    return (
      needTruthy(subscriptionId) ||
      get<Transaction[]>('new', `smb/transactions?subscriptionId=${subscriptionId}`)
    );
  }
);

// #endregion

// #region Recruiter

export const getRecruiter = createAsyncThunk('getRecruiter', ({ id: userId }: Lookup) => {
  return needGuid(userId) || get<IRecruiterInfo>('new', `recruiter/${userId}/info`);
});

export const updateRecruiter = createAsyncThunk(
  'updateRecruiter',
  ({ userId, resource }: ResourceParams<IRecruiter>) => {
    return (
      needGuid(userId) || needTruthy(resource) || put<IRecruiter>('new', 'recruiter', resource)
    );
  }
);

export const getOrganizationUsers = createAsyncThunk(
  'getOrganizationUsers',
  (orgId: string) =>
    needGuid(orgId) || get<IOrgUser[]>('new', `recruiter/organizations/${orgId}/users`)
);

export const addUserToOrg = (orgId: string, userId: string) => {
  return put<boolean>('new', `recruiter/organizations/${orgId}/users/${userId}`);
};

export const getQuotaHistory = createAsyncThunk(
  'getQuotaHistory',
  (userId: string) =>
    needGuid(userId) || get<IQuotaHistory[]>('new', `recruiter/recruiterActivity/${userId}`)
);

export const getTags = createAsyncThunk('getTags', (orgId: string) => {
  return needGuid(orgId) || get<IRecruiterTag[]>('new', `recruiter/tags?orgId=${orgId}`);
});

export const addTag = createAsyncThunk('addTag', ({ orgId, value: tagName }: ValueParams) => {
  return (
    needGuid(orgId) ||
    needTruthy(tagName) ||
    post<IRecruiterTag>('new', `recruiter/tags?orgId=${orgId}&tagName=${tagName}`)
  );
});

export const deleteTag = createAsyncThunk('deleteTag', ({ orgId, value: tagId }: ValueParams) => {
  return needGuid(orgId) || needGuid(tagId) || del('new', `recruiter/tags/${tagId}?orgId=${orgId}`);
});

export const getCandidateStatuses = createAsyncThunk('getCandidateStatus', (orgId: string) => {
  return needGuid(orgId) || get<IRecruiterStatus[]>('new', `recruiter/statuses?orgId=${orgId}`);
});

export const addCandidateStatus = createAsyncThunk(
  'addCandidateStatus',
  ({ orgId, value: status }: ValueParams) => {
    return (
      needGuid(orgId) ||
      needTruthy(status) ||
      post<IRecruiterStatus>('new', `recruiter/statuses?status=${status}&orgId=${orgId}`)
    );
  }
);

export const deleteCandidateStatus = createAsyncThunk(
  'deleteCandidateStatus',
  ({ orgId, value: statusId }: ValueParams) => {
    return (
      needGuid(orgId) ||
      needGuid(statusId) ||
      del('new', `recruiter/statuses/${statusId}?orgId=${orgId}`)
    );
  }
);

export const getUserSearchHistory = createAsyncThunk(
  'getUserSearchHistory',
  ({ startDate, endDate, userId }: DateRangeParams) => {
    const { startIso, endIso, reject } = needDateRange(startDate, endDate);
    return (
      reject ||
      needGuid(userId) ||
      get<ISearchHistory[]>(
        'new',
        `recruiter/searchHistory?startDate=${startIso}&endDate=${endIso}&userId=${userId}`
      )
    );
  }
);

export const deleteUserSearchHistory = createAsyncThunk(
  'deleteUserSearchHistory',
  (userId: string) => needGuid(userId) || del('new', `recruiter/searchHistory?userId=${userId}`)
);

export const verifyUser = createAsyncThunk('verifyUser', (userId: string) => {
  return needGuid(userId) || post<string>('new', `recruiter/${userId}/verifyUser`);
});

export const getCartUsers = (cartId: string) => {
  return needGuid(cartId) || get<IUsersCart[]>('new', `recruiter/cart/${cartId}/users`);
};

export const getUserInfo = createAsyncThunk(
  'getUserInfo',
  (userEmail: string) => needEmail(userEmail) || get<UserInfo>('new', `admin/userInfo/${userEmail}`)
);

export const deleteUserInfo = createAsyncThunk(
  'deleteUserInfo',
  (userEmail: string) => needEmail(userEmail) || del('new', `admin/userInfo/${userEmail}`)
);

export const cancelSubscription = createAsyncThunk(
  'cancelSubscription',
  ({ userId, value: reason }: ValueParams) => {
    return (
      needGuid(userId) ||
      needTruthy(reason) ||
      delWithResult<Record<string, string>>('new', `smb/subscription`, { userId, reason })
    );
  }
);

// #endregion

// #region Salesforce

export const getSFActiveOrders = createAsyncThunk(
  'getSFActiveOrders',
  ({ sfAccountId }: SFOrderParams) => {
    return (
      needTruthy(sfAccountId) ||
      get<IActiveOrder[]>('new', `salesforce/activeorders?sfAccountId=${sfAccountId}`)
    );
  }
);

export const getSFOrderItems = createAsyncThunk(
  'getSFOrderItems',
  ({ orgId, orderId }: SFOrderItemParams) => {
    return (
      needTruthy(orderId) ||
      needGuid(orgId) ||
      get<IOrderItem[]>('new', `salesforce/orderitems?orgId=${orgId}&orderId=${orderId}`)
    );
  }
);

export const updateSFLicense = createAsyncThunk(
  'updateSFLicense',
  async ({ orderItemId, userId, isRemove }: SFOrderItemAssignParams) => {
    if (orderItemId === NoLicenseSku.id && isRemove) {
      // This is an edge case where user has no salesforce license
      // but has active sku and we want to assign them no-license sku
      return (
        needGuid(userId) ||
        needTruthy(orderItemId) ||
        put<boolean>('new', `sku/${orderItemId}/user/${userId}?bool=1`)
      );
    } else {
      const rem = isRemove ? '&remove=1' : '';
      return (
        needGuid(userId) ||
        needTruthy(orderItemId) ||
        put<boolean>(
          'new',
          `salesforce/assignLicense?orderItemId=${orderItemId}&userId=${userId}${rem}`
        )
      );
    }
  }
);

export const updateSFFeature = createAsyncThunk(
  'updateSFFeature',
  ({ featureId, userId, isRemove }: SFFeatureAssignParams) => {
    const rem = isRemove ? '&remove=1' : '';
    return (
      needGuid(userId) ||
      needTruthy(featureId) ||
      put<void>('new', `salesforce/assignFeature?featureId=${featureId}&userId=${userId}${rem}`)
    );
  }
);

// #endregion

// #region SKU

export const getSkuV1 = createAsyncThunk('getSkuV1', () => get<ISku[]>('new', 'sku'));

export const getSkuV2 = createAsyncThunk('getSkuV2', async () => {
  const skuList = await get<ISkuV2[]>('new', 'skuV2');
  return skuList.map((sku) => ({ ...sku, isNew: true }));
});

export const createOrUpdateSkuV1 = createAsyncThunk(
  'createOrUpdateSkuV1',
  ({ resource }: ResourceParams<ISku>) => {
    const apiMethod = resource.id ? put : post;
    return needTruthy(resource) || apiMethod<ISku>('new', 'sku', resource);
  }
);

export const deleteSkuV1 = createAsyncThunk(
  'deleteSkuV1',
  ({ resource }: ResourceParams<ISku>) =>
    needTruthy(resource) || put<ISku>('new', 'sku', { ...resource, isDeleted: true })
);

export const createOrUpdateSkuV2 = createAsyncThunk(
  'createOrUpdateSkuV2',
  ({ resource }: ResourceParams<ISkuV2>) => {
    const apiMethod = resource.id ? put : post;
    return needTruthy(resource) || apiMethod<ISkuV2>('new', 'skuV2', resource);
  }
);

export const deleteSkuV2 = createAsyncThunk(
  'deleteSkuV2',
  (skuId: string) => needTruthy(skuId) || del('new', `skuV2/${skuId}`)
);

export const applySkuOrAddon = createAsyncThunk(
  'applySkuOrAddon',
  ({ userId, skuId }: SkuParams) => {
    return (
      needGuid(userId) || needTruthy(skuId) || put<IRecruiter>('new', `sku/${skuId}/user/${userId}`)
    );
  }
);

// #endregion

// #region SSO

export const getSSOConfig = createAsyncThunk(
  'getSSOConfig',
  (orgId: string) => needGuid(orgId) || get<IssoConfig>('new', `ssoConfig/${orgId}`)
);

export const updateSSOConfig = createAsyncThunk(
  'updateSSOConfig',
  ({ orgId, resource }: ResourceParams<IssoConfig>) => {
    return needGuid(orgId) || put<IssoConfig>('new', `ssoConfig/?orgId=${orgId}`, resource);
  }
);

export const deleteSSOConfig = createAsyncThunk(
  'deleteSSOConfig',
  (orgId: string) => needGuid(orgId) || del('new', `ssoConfig/${orgId}`)
);

export const getSSOHistory = createAsyncThunk('getSSOHistory', ({ value: email }: ValueParams) => {
  return needEmail(email) || get<ISSOHistory[]>('new', `ssoConfig/ssoLogs/${email}`);
});

export const updateOnlySSO = createAsyncThunk(
  'updateOnlySSO',
  ({ orgId, userId, flag: onlySSO }: FlagParams) => {
    return (
      needGuid(orgId) ||
      needGuid(userId) ||
      put<IUser>(
        'new',
        `organizations/${orgId}/users/${userId}/ssoLogin?onlyAllowSSOLogins=${onlySSO}`
      )
    );
  }
);

// #endregion

// #region Users

export const getUser = createAsyncThunk('getUser', (lookup: Lookup) => {
  if (!lookup.id && !lookup.email) return Promise.reject('No Identifier Provided');
  return get<IUser>(
    'new',
    lookup.id ? `users/${lookup.id}` : `admin/users?email=${encodeURIComponent(lookup.email || '')}`
  );
});

export const getUsers = (userEmails: string[]) => {
  return post<IUser[]>('new', 'admin/users', { userEmails });
};

export const getAdminUsers = createAsyncThunk('getAdminUsers', () => {
  return get<IAdminUser[]>('new', 'users/superAdmin/all');
});

export const addAdminUser = createAsyncThunk(
  'addAdminUser',
  (email: string) => needEmail(email) || post<IAdminUser>('new', `users/superAdmin/${email}`)
);

export const updateAdminUserPrivilege = createAsyncThunk(
  'updateAdminUserPrivilege',
  ({ actualUserId, privilege }: AdminUserParams) =>
    needGuid(actualUserId) ||
    put<IAdminUser>('new', `users/superAdmin/${actualUserId}?privilege=${privilege}`)
);

export const deleteAdminUser = createAsyncThunk(
  'deleteAdminUser',
  (adminId: string) => needGuid(adminId) || del('new', `users/superAdmin/${adminId}`)
);

export const getNewUser = createAsyncThunk('getNewUser', (lookup: Lookup) => {
  return (
    needEmail(lookup.email) ||
    get<{ results: INewUser[] }>('new', `newusers?email=${encodeURIComponent(lookup.email || '')}`)
  );
});

export const createNewUser = createAsyncThunk(
  'createNewUser',
  ({ resource }: ResourceParams<CreateUserDef>) =>
    post<ICreateUserResult>('new', 'newUsers', resource as unknown as Record<string, unknown>)
);

// Activated users MUST remove any SalesForce license with the updateSFLicense thunk before calling
export const deleteUser = createAsyncThunk(
  'deleteUser',
  ({ userId, newUserId, flag: isActivated }: FlagParams) => {
    const isNewUser = !!newUserId && !isActivated;

    if (isNewUser) {
      return needGuid(newUserId) || del('new', `newUsers/${newUserId}`);
    }

    return needGuid(userId) || del('new', `recruiter/user/${userId}`);
  }
);

export const sendActivationEmail = createAsyncThunk(
  'sendActivationEmail',
  ({ newUserId }: ParamsBase) =>
    needGuid(newUserId) || post<void>('new', `newUsers/sendActivationEmail/${newUserId}`)
);

export const updateOrgAdmin = createAsyncThunk<INewUser | IRecruiter, FlagParams>(
  'setOrgAdmin',
  ({ userId, newUserId, orgId, flag: isOrgAdmin }: FlagParams) => {
    if (newUserId) {
      return (
        needGuid(newUserId) ||
        put<INewUser>(
          'new',
          `newUsers/${newUserId}/update?userType=${isOrgAdmin ? 'admin' : 'standard'}`
        )
      );
    }

    return (
      needGuid(userId) ||
      needGuid(orgId) ||
      put<IRecruiter>(
        'new',
        `recruiter/organizations/${orgId}/users/${userId}/admin?action=${
          isOrgAdmin ? 'add' : 'remove'
        }`
      )
    );
  }
);

// #endregion

// #region Workflow (Messaging)

export const getEmailSequences = createAsyncThunk('getEmailSequences', (userId: string) => {
  return (
    needGuid(userId) || get<IEmailSequence[]>('new', `workflow/emailSequences?userId=${userId}`)
  );
});

export const getMailboxConfigs = createAsyncThunk('getMailboxConfigs', (userId: string) => {
  return (
    needGuid(userId) ||
    get<IMailboxConfig[]>('old', `workflow/mailboxConfig?userId=${userId}&showAccountInfo=true`)
  );
});

export const getMessagingHistory = createAsyncThunk(
  'getMessagingHistory',
  ({ userId, value: type }: ValueParams) => {
    if (type !== 'all' && !ActivityTypes.includes(type)) {
      return Promise.reject('Invalid Activity Type');
    }

    const activityType = type !== 'all' ? `?activityType=${type}` : '';
    return (
      needGuid(userId) ||
      get<IEmailActivity[]>('new', `workflow/emailActivity/${userId}${activityType}`)
    );
  }
);

export const getEmailTrackingReport = createAsyncThunk(
  'getEmailTrackingReport',
  ({ startDate, endDate }: DateRangeParams) => {
    return (
      needTruthy(startDate) ||
      needTruthy(endDate) ||
      get<IEmailTracking[]>('old', `workflow/generateEmailTrackingReport/${startDate}/${endDate}`)
    );
  }
);

// #endregion

// #region Auth

export const getAuthStatus = createAsyncThunk('getAuthStatus', (isNewApi?: boolean) =>
  get<IPassportUser>(isNewApi ? 'new' : 'old', 'auth/status')
);

export const getLoginHistory = createAsyncThunk(
  'getLoginHistory',
  ({ value: email }: ValueParams) => {
    return needEmail(email) || get<ILoginHistory[]>('new', `admin/authLogs?email=${email}`);
  }
);

export const getLogout = createAsyncThunk('getLogout', (isNewApi?: boolean) =>
  get<string>(isNewApi ? 'new' : 'old', 'auth/logout')
);

// #endregion

// #region Monitor

export const getMonitorCategories = createAsyncThunk(
  'getMonitorCategories',
  (clientAdminLogs: boolean) =>
    get<MonitorCategories>(clientAdminLogs ? 'old' : 'new', 'monitor/categories')
);

export const getMonitorLogs = createAsyncThunk(
  'getMonitorLogs',
  ({ clientAdminLogs, logOptions }: MonitorLogParams) =>
    post<MonitorLog[]>(clientAdminLogs ? 'old' : 'new', 'monitor/logs', { ...logOptions })
);

// #endregion

// #region Tooltips

export const getTooltips = createAsyncThunk('getTooltips', () =>
  get<TooltipDefinition[]>('new', 'tooltips')
);

export const postTooltip = createAsyncThunk('postTooltip', (input: TooltipInput) =>
  post<TooltipDefinition>('new', 'tooltips', { ...input })
);

// #endregion

// #region Integrations V2

export const getExtractionStatus = async (
  connectionId: string
): Promise<IExtractionStatus | undefined> => {
  const extractionStatus = await getExtractionSchedule(connectionId);
  try {
    const latestJobExtractionStatus = await getLatestJobExtractionStatus(connectionId);
    extractionStatus.lastTransformation = latestJobExtractionStatus?.lastTransformation;
  } catch {
    extractionStatus.lastTransformation = undefined;
  }
  return extractionStatus;
};

export const getExtractionSchedule = async (connectionId: string): Promise<IExtractionStatus> => {
  return (
    needTruthy(connectionId) ||
    post<IExtractionStatus>(
      'new',
      `integrations-proxy/integ-api/GET/${getIntegrationPath('extraction/schedule')}`,
      undefined,
      { 'x-account-connectionid': connectionId }
    )
  );
};

export const getExtractionProperties = async (
  connectionId: string
): Promise<Record<string, unknown>[]> => {
  return await (needTruthy(connectionId) ||
    post<Record<string, unknown>[]>(
      'new',
      `integrations-proxy/integ-api/GET/${getIntegrationPath(
        'extraction/properties?showAll=true'
      )}`,
      undefined,
      { 'x-account-connectionid': connectionId }
    ));
};

const getLatestJobExtractionStatus = async (
  connectionId: string
): Promise<IExtractionStatus | undefined> => {
  const jobs = await (needTruthy(connectionId) ||
    (await post<INormalizedJobs[]>('new', `integrations-proxy/integ-api/GET/jobs`, undefined, {
      'x-account-connectionid': connectionId,
    })));
  const latestJob = jobs.sort(
    (a, b) => new Date(b.updatedDateTime).getTime() - new Date(a.updatedDateTime).getTime()
  )[0];
  if (!latestJob) return undefined;

  const latestJobEndTime = await getLatestJobEndTime(connectionId, latestJob.id);
  return {
    connectionId,
    pKey: latestJob.id,
    nextOccurrenceTime: '',
    lastTransformation: latestJobEndTime,
    lastOccurenceTime: latestJob.updatedDateTime,
    circuitStateBroken: false,
    isActive: true,
    scheduleCrontab: '0 0 * * *',
  };
};

const getLatestJobEndTime = async (connectionId: string, jobId: string): Promise<string> => {
  const jobRuns = await (needTruthy(connectionId) ||
    (await post<IDataBricksJobRunsApiData[]>(
      'new',
      `integrations-proxy/integ-api/GET/${getIntegrationPath(`databricks/jobs/${jobId}/jobRuns`)}`,
      undefined,
      { 'x-account-connectionid': connectionId }
    )));

  return jobRuns.reduce((latest, current) => {
    return new Date(current.end_time).getTime() > new Date(latest.end_time).getTime()
      ? current
      : latest;
  }, jobRuns[0])?.end_time;
};

export const pauseScheduler = async (connectionId: string): Promise<void> => {
  await (needTruthy(connectionId) ||
    post(
      'new',
      `integrations-proxy/integ-api/POST/${getIntegrationPath('extraction/schedule/pause')}`,
      undefined,
      { 'x-account-connectionid': connectionId }
    ));
};

export const unpauseScheduler = async (connectionId: string): Promise<void> => {
  await (needTruthy(connectionId) ||
    post(
      'new',
      `integrations-proxy/integ-api/POST/${getIntegrationPath('extraction/schedule/unpause')}`,
      undefined,
      { 'x-account-connectionid': connectionId }
    ));
};

export const setTransformationCircuitBreakFlag = async (connectionId: string): Promise<void> => {
  await (needTruthy(connectionId) ||
    post(
      'new',
      `integrations-proxy/integ-api/POST/${getIntegrationPath(
        'transformationDriver/setCircuitBreakFlag'
      )}`,
      undefined,
      { 'x-account-connectionid': connectionId }
    ));
};

export const configureCronTabSchedule = async (
  connectionId: string,
  cronTabSchedule: string
): Promise<void> => {
  await (needTruthy(connectionId) ||
    post(
      'new',
      `integrations-proxy/integ-api/POST/${getIntegrationPath('extraction/schedule/cronTab')}`,
      cronTabSchedule,
      { 'x-account-connectionid': connectionId }
    ));
};

export const getExtractionConfiguration = async (
  connectionId: string
): Promise<IExtractionConfiguration> => {
  return (
    needTruthy(connectionId) ||
    post<IExtractionConfiguration>(
      'new',
      `integrations-proxy/integ-api/GET/${getIntegrationPath('extraction/configuration')}`,
      undefined,
      { 'x-account-connectionid': connectionId }
    )
  );
};

export const saveConfiguration = async (
  connectionId: string,
  processesJsonResult: IExtractionProcess[]
): Promise<void> => {
  await (needTruthy(connectionId) ||
    post(
      'new',
      `integrations-proxy/integ-api/PUT/${getIntegrationPath(
        'extraction/configuration/processes'
      )}`,
      processesJsonResult as unknown as Record<string, unknown>,
      { 'x-account-connectionid': connectionId }
    ));
};

export const terminateExtraction = async (connectionId: string): Promise<void> => {
  await (needTruthy(connectionId) ||
    post(
      'new',
      `integrations-proxy/integ-api/POST/${getIntegrationPath('extraction/terminate')}`,
      undefined,
      { 'x-account-connectionid': connectionId }
    ));
};

export const startExtraction = async (connectionId: string): Promise<void> => {
  await (needTruthy(connectionId) ||
    post(
      'new',
      `integrations-proxy/integ-api/POST/${getIntegrationPath('extraction/start')}`,
      undefined,
      {
        'x-account-connectionid': connectionId,
      }
    ));
};

export const getTransformationDriverProcessFlag = async (
  connectionId: string
): Promise<boolean> => {
  const data: ITransformationDriverMetadata = await (needTruthy(connectionId) ||
    post<ITransformationDriverMetadata>(
      'new',
      `integrations-proxy/integ-api/GET/${getIntegrationPath('transformationDriver/Metadata')}`,
      undefined,
      { 'x-account-connectionid': connectionId }
    ));
  return data.processFlag;
};

export const getServiceBusMetrics = async (
  connectionId: string,
  atsType: string
): Promise<IServiceBusMetrics> => {
  const data: IServiceBusMetrics = await (needTruthy(connectionId) ||
    post<IServiceBusMetrics>(
      'new',
      `integrations-proxy/integ-api/GET/${getIntegrationPath(
        `transformationDriver/messageCount?atsType=${atsType}&category=ats`
      )}`,
      undefined,
      { 'x-account-connectionid': connectionId }
    ));
  return data;
};

export const getDatabricksHistory = async (
  connectionId: string
): Promise<ITransformationJobRuns[]> => {
  const data: ITransformationJobRuns[] = await (needTruthy(connectionId) ||
    post<ITransformationJobRuns[]>(
      'new',
      `integrations-proxy/integ-api/GET/${getIntegrationPath('transformationDriver/jobRuns')}`,
      undefined,
      { 'x-account-connectionid': connectionId }
    ));
  return data;
};

export const flipProcessFlag = async (
  connectionId: string,
  metadataFieldName: string
): Promise<boolean> => {
  const data: ITransformationDriverMetadata = await (needTruthy(connectionId) ||
    post<ITransformationDriverMetadata>(
      'new',
      `integrations-proxy/integ-api/POST/${getIntegrationPath(
        `transformationDriver/Metadata/${metadataFieldName}`
      )}`,
      undefined,
      { 'x-account-connectionid': connectionId }
    ));
  return data.processFlag;
};

export const deleteDatabricksEntry = async (
  connectionId: string,
  jobRunId: string
): Promise<void> => {
  const data: void = await (needTruthy(connectionId) ||
    post<void>(
      'new',
      `integrations-proxy/integ-api/DELETE/${getIntegrationPath(
        `transformationDriver/jobRuns/${jobRunId}`
      )}`,
      undefined,
      { 'x-account-connectionid': connectionId }
    ));
  return data;
};

export const handleFetchEntityDetails = async (
  connectionId: string,
  entityId: string,
  entityType: string
): Promise<Record<string, unknown>[]> => {
  const data: Record<string, unknown>[] = await (needTruthy(connectionId) ||
    post<Record<string, unknown>[]>(
      'new',
      `integrations-proxy/integ-api/GET/${getIntegrationPath(
        `auditor/logs/${entityType}/${entityId}`
      )}`,
      undefined,
      { 'x-account-connectionid': connectionId }
    ));
  return data;
};

export const handleFetchEntityPayloadJSON = async (
  connectionId: string,
  entityId: string,
  entityType: string,
  domain: string
): Promise<Record<string, unknown>[]> => {
  return await (needTruthy(connectionId) ||
    post<Record<string, unknown>[]>(
      'new',
      `integrations-proxy/distribution-api/GET/${getIntegrationPath(
        `DataRetrieval/${getCamelCaseEntityType(
          entityType,
          entityId
        )}&domain=${domain}&connectionId=${connectionId}`
      )}`,
      undefined,
      undefined
    ));
};

export const fetchReports = async (connectionId: string): Promise<IReportDetails[]> => {
  const reportStates = 'Failed,Processing,Complete';
  return await (needTruthy(connectionId) ||
    post<IReportDetails[]>(
      'new',
      `integrations-proxy/integ-api/GET/${getIntegrationPath(`reports?states=${reportStates}`)}`,
      undefined,
      { 'x-account-connectionid': connectionId }
    ));
};

export const retryFailedReport = async (connectionId: string, reportId: string): Promise<void> => {
  await (needTruthy(connectionId) ||
    post<void>(
      'new',
      `integrations-proxy/integ-api/POST/${getIntegrationPath(`reports/retry/${reportId}`)}`,
      undefined,
      { 'x-account-connectionid': connectionId }
    ));
};

export const requestConsistencyReport = async (
  connectionId: string,
  consistencyReportRequest: IConsistencyReport
): Promise<void> => {
  await (needTruthy(connectionId) ||
    post<void>(
      'new',
      `integrations-proxy/integ-api/POST/${getIntegrationPath(`reports/consistency_report`)}`,
      consistencyReportRequest as unknown as Record<string, unknown>,
      { 'x-account-connectionid': connectionId }
    ));
};

export const setupInitializeV2Connections = async (connectionId: string): Promise<void> => {
  await (needTruthy(connectionId) ||
    post<void>(
      'new',
      `integrations-proxy/integ-api/POST/${getIntegrationPath(
        `connections/${connectionId}/initializeV2`
      )}`,
      undefined,
      {
        'x-account-connectionid': connectionId,
      }
    ));
};

// #endregion

// #region Other Endpoints
export const getSubscriptionHistory = createAsyncThunk(
  'getSubscriptionHistory',
  (userId: string) => {
    return (
      needGuid(userId) ||
      get<ISubscriptionHistory[]>('new', `subscriptions/subscriptionActivity/${userId}`)
    );
  }
);

export const getPasswordResetUrl = createAsyncThunk(
  'getPasswordResetUrl',
  ({ value: email }: ValueParams) => {
    return needEmail(email) || post<string>('new', `users/initiateResetPassword?email=${email}`);
  }
);
// #endregion

// Validation Helpers
function needDateRange(start: string, end: string) {
  const startIso = asDateISOString(start);
  if (!startIso) return { reject: Promise.reject(`Require Date, received "${start}"`) };
  const endIso = asDateISOString(end);
  if (!endIso) return { reject: Promise.reject(`Require Date, received "${end}"`) };
  return { startIso, endIso };
}

function needEmail(email?: string) {
  if (!isEmail(email)) return Promise.reject(`Require Email, received "${email}"`);
}

function needGuid(guid?: string) {
  if (!isGuid(guid)) return Promise.reject(`Require Guid, received "${guid}"`);
}

function needTruthy(value: unknown) {
  if (!value) return Promise.reject(`Require Non-Empty, received "${value}"`);
}

// Fetch Helpers

function fullUrl(base: APIBase, url: string): string {
  return `/${base === 'old' ? 'api' : `api-${base}`}/${url}`;
}

function get<T>(base: APIBase, url: string): Promise<T> {
  return fetchWrapper(fullUrl(base, url), { method: 'GET' });
}

function del(base: APIBase, url: string): Promise<boolean> {
  return delWithResult<boolean>(base, url);
}

function delWithResult<T>(base: APIBase, url: string, body?: RequestBody): Promise<T> {
  const { requestBody, requestHeaders } = getRequestData(body);
  return fetchWrapper(fullUrl(base, url), {
    method: 'DELETE',
    body: requestBody,
    headers: requestHeaders,
  });
}

function post<T>(
  base: APIBase,
  url: string,
  body?: RequestBody,
  headers?: HeadersInit
): Promise<T> {
  const { requestBody, requestHeaders } = getRequestData(body);
  return fetchWrapper(fullUrl(base, url), {
    method: 'POST',
    body: requestBody,
    headers: {
      ...requestHeaders,
      ...(headers || {}),
    },
  });
}

function put<T>(base: APIBase, url: string, body?: RequestBody): Promise<T> {
  const { requestBody, requestHeaders } = getRequestData(body);
  return fetchWrapper(fullUrl(base, url), {
    method: 'PUT',
    body: requestBody,
    headers: requestHeaders,
  });
}

// Request Helper
function getRequestData(body?: RequestBody) {
  let requestHeaders = {};
  let requestBody: BodyInit | undefined = undefined;
  if (body instanceof Blob) {
    requestHeaders = {
      'Content-Type': body.type,
      'Content-Length': body.size,
    };
    requestBody = body;
  } else if (body instanceof FormData) {
    requestBody = body;
  } else {
    requestBody = body ? JSON.stringify(body) : undefined;
  }
  return { requestBody, requestHeaders };
}
